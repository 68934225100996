// Here you can add other styles
// .autocomplete {
//   width: 100%;
//   position: relative;

//   input {
//     border: 1px solid #cecece;
//     width: 100%;
//     border-radius: 5px;
//   }

//   .list-autocomplete-box {
//     position: absolute;
//     background-color: #fff;
//     border: 1px solid rgba(0, 0, 21, 0.125);
//     border-radius: 5px;
//     width: 100%;
//     max-height: 250px;
//     overflow-y: scroll;
//     z-index: 99999;

//     .list-item:hover {
//       background-color: #cecece;
//       transition: background-color 0.5s;
//     }
//   }
// }
.fullscreen.fullscreen-enabled {
  background: #fff;
  padding: 9px 14px 0 32px;
  overflow-y: auto;
  overflow-x: hidden;
}
#selectProduto #combo-box-demo-others-products {
  font-size: 12px;
  color: rgb(92, 104, 115);
  font-family: inherit;
}

.header-orcamento {
  margin-bottom: -15px !important;
}

.form-filter-box {
  margin-left: 20px;

  select {
    border: 1px solid #cecece;
    width: 100%;
    border-radius: 5px;
    padding: 2px;
    margin-top: 10px;
    height: 37px;
  }
}

.form-filter-div {
  margin-right: 10px;
}

.form-filter-div .form-check-input {
  border: 1px solid #cecece;
  border-radius: 2px;
}

.section-title {
  font-size: 16px;
  color: #000000;
  font-weight: 400;
}

.c-sidebar.c-sidebar-dark.c-sidebar-lg-show.c-sidebar-fixed.c-sidebar-unfoldable {
  z-index: 99999999999 !important;
}

.client-data-title {
  font-size: 12px;
  color: #5a5a5a;
}

fieldset.MuiOutlinedInput-notchedOutline.css-igs3ac {
  border: 0 !important;
}

/* .error-text-input input {
    background-color: #C0392B !important;
} */

.react-select-container {
  flex: 1;
  /* width: 100%; */
}

.css-1rhbuit-multiValue.react-select__multi-value {
  margin-top: -5px;
  height: 20px;
}

// .MuiAutocomplete-root {
//     height: 24px;
// }

// .MuiAutocomplete-inputRoot {
//     height: 24px;
//     border: 1px solid #E4E7EA !important;
//     border-radius: 2.5px !important;
// }

// .MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
//     border: none;
// }

// .fullscreen.fullscreen-enabled {
//     background: #fff;
//     padding: 30px;
// }

// .MuiAutocomplete-input {
//     height: 24px !important;
//     padding: 0 !important;
//     margin-top: -10px !important;
// }

/* .form-group-autocomplete {
    margin-top: 28px !important;
} */
.MuiAutocomplete-input {
  text-align: start !important;
}
#asynchronous-demo-label {
  top: -15px;
}

.button-client-data {
  margin-top: 10px;
  display: none;
}

.date-input {
  height: 24px;
  width: 100%;
  font-size: 12px;
  color: #5c6873 !important;
  padding: 0 6px !important;
  border-radius: 0.25rem;
  border: 1px solid #e4e7ea;

  &:disabled {
    background-color: #e4e7ea;
  }
}

.form-group-pivo-client-col {
  padding: 0 3px !important;
  height: 24px !important;
  margin-bottom: 5px;
}

input {
  padding: 6px;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.align-bottom {
  align-items: flex-end;
}

.justify-content {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.totalValue {
  font-size: 14px;
}

.card-content-row {
  padding-top: 24px !important;
}

.highlighted {
  color: #c0392b;
}

.medium-margin-bottom {
  margin-bottom: 50px !important;
}

.section-button {
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: 1400px) {
    font-size: 10px;
  }

  @media screen and (max-width: 1360px) {
    white-space: inherit;
  }

  @media screen and (max-width: 1199px) {
    font-size: 12px;
  }
}

.add-item-button {
  border: none !important;
  box-shadow: none !important;
  padding: 5px !important;
  font-size: 16px;
  color: #e74c3c;
  /* display: flex;
    align-self: center; */
}

.field-input-col {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.card-content {
  padding: 20px;

  .card-content {
    box-shadow: 0 3px 6px #00000029 !important;
  }

  .card-content-header,
  .card-content-body {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.button-group {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 5px;
}

.action-data-table {
  padding-left: 2.5px !important;
  padding-right: 2.5px !important;
}

.sidebar-logo-box {
  background-color: #34495e !important;
  padding: 20px 0;
}

.input-label {
  margin: 0;
}

.breadcrum-application {
  position: absolute;
  left: 10px;
}

.pivo-content {
  margin-top: -20px;

  .form-group {
    margin-bottom: 7px;
  }

  input {
    text-align: end;
  }
}

.pivo-content .card-content {
  padding: 6px 10px;
  margin: -2px -7px;

  .button-action-box {
    display: flex;
    flex: 1;
    align-items: flex-end;
    justify-content: flex-end;
  }
}

.table-margin-negative {
  width: 100%;
  margin-bottom: 10px;
  margin-top: -60px;
  padding-left: 0;
  padding-right: 0;
}

.mobile-subheader {
  display: none;
}

.item-logo a {
  display: none;
  align-items: center;
  justify-content: center;
}

.c-sidebar-nav-link,
.c-sidebar-nav-dropdown-toggle {
  font-weight: 600;
  font-size: 14px;
}

.c-sidebar-nav-dropdown-items a {
  font-weight: 400;
}

.c-datatable-items-per-page {
  label.mfe-2 {
    color: #000000;
    font-size: 16px;
    font-weight: 400 !important;
  }

  select {
    color: #000;
    font-size: 16px;
  }
}

.breadcrum-application {
  font-size: 14px;
  color: #a99dae;
  font-weight: 600;

  a {
    color: #e74c3c;
  }
}

.table.table-hover {
  border: none;

  th,
  td {
    color: #000000;
    font-size: 14px;
    font-weight: 400 !important;
  }

  td {
    border-top-color: #e4e7ea;
  }

  th {
    border: none;

    svg {
      display: none;
    }
  }

  tr.table-sm th {
    padding-top: 0;
    padding-bottom: 19px;
  }
}

.c-sidebar-nav:not(.aside-menu-content) {
  background-color: #2c3e50 !important;

  .c-sidebar-nav-item,
  .c-sidebar-nav-dropdown {
    background-color: #34495e !important;
  }

  &.ps {
    .c-sidebar-nav-dropdown .c-sidebar-nav-item {
      /* padding-left: 20px; */

      &:hover {
        background-color: #321fdb !important;
        transition: all 0.3s;
      }
    }

    .c-sidebar-nav-dropdown-items .c-sidebar-nav-link {
      padding-left: 80px;
    }

    .c-sidebar-nav-link {
      &:hover {
        background-color: transparent !important;
      }
    }
  }
}

.c-sidebar-minimizer.c-d-md-down-none {
  background-color: #34495e !important;
}

.img-logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile_image {
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 50%;
}

.btn-show-sidebar-mobile {
  position: absolute;
  left: 0;
}

.btn-size-limited {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
  font-size: 12px;
}

.inverted-text {
  text-align: right;
}

.btn-action {
  border: none;
  width: 100%;
  background-color: transparent;

  &:focus {
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
  }
}

.checkbox-content {
  display: flex;
  align-items: center;
}

input[type="radio"] {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
  background-color: #fff;

  &:checked {
    background-color: #e74c3c;
  }
}

.button-pivo-action + .button-pivo-action {
  margin-left: 5px;
}

.orcamento-actions {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-bottom: 10px;

  button {
    margin-left: 10px;
  }
}

.field-error {
  border-color: #e74c3c !important;
}

.orcamento-button {
  display: flex;
  align-items: center;

  span {
    margin-left: 5px;
  }
}

@media (max-width: 1399px) {
  .button-group {
    justify-content: flex-start;
  }
}

@media (min-width: 992px) {
  .btn-size-limited {
    max-width: 120px;
  }
}

@media (max-width: 991px) {
  .breadcrum-application {
    left: 50px;
  }

  .button-client-data {
    display: inline;
  }

  .button-group {
    margin-top: 35px;
  }

  .form-register-title {
    font-size: 24px;
    text-align: center;
    padding: 5px 0;

    &:not(.not-border) {
      border-top: 1px solid #cecece;
    }
  }

  .item-logo a {
    display: flex;
  }

  .form-register-subtitle {
    font-size: 22px;
    text-align: center;
    padding: 5px 0;

    &:not(.not-border) {
      border-top: 1px solid #cecece;
    }
  }
}

@media (max-width: 768px) {
  .table-margin-negative {
    margin-top: 0;
  }

  .breadcrum-application {
    display: none;
  }

  .mobile-subheader {
    display: block;
  }
}

@media (max-width: 660px) {
  .container-fluid {
    padding: 0 10px;
  }
}

@media (max-width: 576px) {
  .pagination.justify-content-start {
    justify-content: center !important;
  }

  .checkbox-content {
    margin-bottom: 10px;
    justify-content: flex-end;
  }
}
